import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

import LazyImage from 'components/shared/lazy-image'
import Container from 'components/shared/container'
import { Text } from 'components/shared/typography'
import Icon from 'components/shared/icon'

import Pagination from 'components/pages/accessories-browser/pagination'

import scrollToSection from 'utils/scrollToSection'

import media from 'styles/media'

import menuIcon from 'assets/icons/menu-icon.svg'
import zoomIcon from 'assets/icons/zoom.svg'
import closeIcon from 'assets/icons/close-mobile.svg'
import arrowIcon from 'assets/icons/arrow-right-black.svg'
import sortingIcon from 'assets/icons/sorting-icon.svg'
import checkIcon from 'assets/icons/check-white.svg'

import { ImageDataLike } from 'gatsby-plugin-image'

const Wrapper = styled.div``

const MobileHeaderWrapper = styled.div`
  ${media.lg.min} {
    display: none;
  }
`

const DesktopCategoriesWrapper = styled.div`
  display: none;

  ${media.lg.min} {
    display: block;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 45px;
  }
`

const CategoryButtonIcon = styled.img``

const CategoryButton = styled.button<{ active: boolean }>`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 16px 0;
  transition: 0.3s;
  border-bottom: 2px solid
    ${({ theme, active }) => (active ? theme.colors.black : 'transparent')};
`

const SelectButtonsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 50%);
`

const SelectButton = styled.button<{ active?: boolean }>`
  padding: 18px 22px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.1s;

  &:nth-child(1) {
    border-right: 1px solid #b9b9b9;
    border-bottom: 1px solid #b9b9b9;
  }

  &:nth-child(2) {
    border-bottom: 1px solid #b9b9b9;
  }

  ${({ active }) =>
    active &&
    css`
      background-color: #eee;
    `}
`

const SelectButtonIcon = styled.img``

const MobileSearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0 20px;

  ${media.lg.min} {
    display: none;
  }
`

const SearchInput = styled.input`
  padding: 8px 40px 8px 17px;
  height: 45px;
  width: 100%;
  font-size: 15px;
  border: 1px solid ${({ theme }) => theme.colors.black};
  background-image: url(${zoomIcon});
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) 50%;
  background-size: 26px 26px;

  ${media.lg.min} {
    margin-bottom: 45px;
  }
`

const MainWrapper = styled.div`
  ${media.lg.min} {
    padding-top: 60px;
    display: grid;
    grid-template-columns: 260px 1fr;
    gap: 46px;
  }
`

const DesktopFiltersWrapper = styled.div`
  display: none;

  ${media.lg.min} {
    display: block;
  }
`

const MainViewWrapper = styled.div``

const DesktopBarWrapper = styled.div`
  display: none;

  ${media.lg.min} {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ebebeb;
    margin-bottom: 45px;
    padding: 10px 20px;
  }
`

const DesktopBarProductsInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const DesktopBarSortInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  padding: 5px 12px;
  background: ${({ theme }) => theme.colors.white};
  cursor: pointer;
`

const DesktopBarSortDropdownWrapper = styled.div<{ opened: boolean }>`
  position: absolute;
  top: 100%;
  right: 0;
  padding: 10px 18px;
  background-color: #ebebeb;
  min-width: 236px;
  transition: 0.2s;
  transition-delay: 0.1s;
  visibility: hidden;
  opacity: 0;
  z-index: 1;

  ${({ opened }) =>
    opened &&
    css`
      visibility: visible;
      opacity: 1;
    `}
`

const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  padding: 14px 0;
  cursor: pointer;

  &:not(&:last-child) {
    border-bottom: 1px solid #969696;
  }
`

const DropdownItemCheckbox = styled.span<{ active: boolean }>`
  width: 17px;
  height: 17px;
  border: 1px solid ${({ theme }) => theme.colors.black};
  border-radius: 2px;
  transition: 0.1s;

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.colors.black};
      background-image: url(${checkIcon});
      background-repeat: no-repeat;
      background-position: center;
    `}
`

const ProductsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1px;
  row-gap: 20px;

  ${media.lg.min} {
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
`

const ProductImageWrapper = styled.div`
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.sm.min} {
    height: 237px;
  }

  ${media.lg.min} {
    height: 297px;
    padding: 30px 10px;
  }

  ${media.xxxl.min} {
    height: 275px;
    padding: 10px 35px;
  }
`

const ProductInfoWrapper = styled.div`
  background-color: #ebebeb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 8px 10px 8px;
  transition: 0.3s;
  min-height: 60px;
  flex-grow: 1;
`

const ProductNameText = styled(Text)`
  font-size: 14px;
  margin: 0;
  text-align: center;

  ${media.lg.min} {
    font-size: 16px;
  }
`

const ProductPriceText = styled(Text)`
  font-size: 14px;
  margin: 0;
  text-align: center;

  span {
    color: ${({ theme }) => theme.colors.red};
  }
`

const ProductTileWrapper = styled.div`
  border: 1px solid #ebebeb;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  flex-direction: column;

  &:hover {
    ${ProductInfoWrapper} {
      background-color: ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.white};
    }

    ${ProductNameText}, ${ProductPriceText} {
      color: ${({ theme }) => theme.colors.white};

      span {
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }
`

const MobileMenuWrapper = styled.div<{ active: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.white};
  z-index: 102;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
  transform: translateX(101%);

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      visibility: visible;
      transform: translateX(0);
    `}
`

const MobileSortingMenuWrapper = styled.div`
  padding: 22px 44px;
`

const MobileCategoryMenuWrapper = styled.div`
  padding: 22px 44px;
`

type Props = {
  products: Array<{
    name: string
    category: string
    link: string
    priceText: string | null
    image: { src: ImageDataLike; alt: string }
    publishedDate: string
  }>
}

const AccessoriesBrowser: React.FC<Props> = ({ products }) => {
  const desiredOrder = ['ATV', 'SSV/UTV', 'Motocykle', 'Generatory']

  const categories = Array.from(
    new Set(products.map((product) => product.category))
  ).sort((a, b) => {
    const indexA = desiredOrder.indexOf(a)
    const indexB = desiredOrder.indexOf(b)

    if (indexA === -1 && indexB === -1) {
      return 0
    }
    if (indexA === -1) {
      return 1
    }
    if (indexB === -1) {
      return -1
    }
    return indexA - indexB
  })

  const [activeCategory, setActiveCategory] = useState<string | null>(null)

  const [filteredProducts, setFilteredProducts] = useState(products)

  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    if (!inputValue) {
      setFilteredProducts(
        products.filter((product) => {
          if (activeCategory) {
            return product.category === activeCategory
          }
          return true
        })
      )
    } else {
      setFilteredProducts(
        products.filter((product) =>
          product.name.toLowerCase().includes(inputValue.toLowerCase())
        )
      )
    }
  }, [activeCategory, inputValue, products])

  const [currentPage, setCurrentPage] = useState(0)
  const [tilesPerPage, setTilesPerPage] = useState(6)

  const changePage = (index: number) => {
    setCurrentPage(index)
    scrollToSection('#accessories-browser')
  }

  const pageTiles = filteredProducts.slice(
    currentPage * tilesPerPage,
    (currentPage + 1) * tilesPerPage
  )

  const maxPage = Math.ceil(filteredProducts.length / tilesPerPage) - 1

  useEffect(() => {
    setCurrentPage(0)
  }, [])

  const [desktopSortDropdownOpened, setDesktopSortDropdownOpened] =
    useState(false)

  const [sortMethod, setSortMethod] = useState<SortMethod>(
    'Od najnowszego do najstarszego'
  )

  type SortMethod =
    | 'Od najnowszego do najstarszego'
    | 'Od najstarszego do najnowszego'
    | 'Alfabetycznie, A-Z'
    | 'Alfabetycznie, Z-A'

  const sortMethods: SortMethod[] = [
    'Od najnowszego do najstarszego',
    'Od najstarszego do najnowszego',
    'Alfabetycznie, A-Z',
    'Alfabetycznie, Z-A',
  ]

  const sortProducts = (method: SortMethod) => {
    let sortedProducts
    switch (method) {
      case 'Od najnowszego do najstarszego':
        sortedProducts = [...filteredProducts].sort(
          // @ts-ignore
          (a, b) => new Date(b.publishedDate) - new Date(a.publishedDate)
        )
        break
      case 'Od najstarszego do najnowszego':
        sortedProducts = [...filteredProducts].sort(
          // @ts-ignore
          (a, b) => new Date(a.publishedDate) - new Date(b.publishedDate)
        )
        break
      case 'Alfabetycznie, A-Z':
        sortedProducts = [...filteredProducts].sort((a, b) =>
          a.name.localeCompare(b.name)
        )
        break
      case 'Alfabetycznie, Z-A':
        sortedProducts = [...filteredProducts].sort((a, b) =>
          b.name.localeCompare(a.name)
        )
        break
      default:
        sortedProducts = filteredProducts
    }
    setFilteredProducts(sortedProducts)
  }

  useEffect(() => {
    sortProducts(sortMethod)
  }, [sortMethod])

  const [mobileMenu, setMobileMenu] = useState({
    opened: false,
    mode: 'sorting',
  })

  return (
    <Wrapper id="accessories-browser">
      <MobileHeaderWrapper>
        <SelectButtonsWrapper>
          <SelectButton
            onClick={() =>
              setMobileMenu({
                opened: true,
                mode: 'category',
              })
            }
          >
            Kategorie
            <SelectButtonIcon src={menuIcon} alt="menu icon" />
          </SelectButton>
          <SelectButton
            onClick={() =>
              setMobileMenu({
                opened: true,
                mode: 'sorting',
              })
            }
          >
            Sortowanie
            <SelectButtonIcon src={sortingIcon} alt="sorting icon" />
          </SelectButton>
        </SelectButtonsWrapper>
        <Container>
          <MobileSearchWrapper>
            <Text align="center" margin="10px">
              <b>{filteredProducts.length}</b> Produkty
            </Text>
            <SearchInput
              placeholder="Wyszukaj produkt"
              value={inputValue}
              onChange={(e) => {
                if (inputValue && activeCategory) {
                  setActiveCategory(null)
                }
                setInputValue(e.target.value)
              }}
            />
          </MobileSearchWrapper>
        </Container>
      </MobileHeaderWrapper>
      <Container>
        <MainWrapper>
          <DesktopFiltersWrapper>
            <SearchInput
              placeholder="Wyszukaj produkt"
              value={inputValue}
              onChange={(e) => {
                if (e.target.value && activeCategory) {
                  setActiveCategory(null)
                }
                setInputValue(e.target.value)
              }}
            />

            <DesktopCategoriesWrapper>
              {categories.map((category) => (
                <CategoryButton
                  key={category}
                  active={activeCategory === category}
                  onClick={() => {
                    setActiveCategory(
                      activeCategory === category ? null : category
                    )
                    setInputValue('')
                    changePage(0)
                  }}
                >
                  {category}
                  <CategoryButtonIcon src={arrowIcon} alt="arrow icon" />
                </CategoryButton>
              ))}
            </DesktopCategoriesWrapper>
          </DesktopFiltersWrapper>
          <MainViewWrapper>
            <DesktopBarWrapper>
              <DesktopBarProductsInfoWrapper>
                <Text size={17} weight={300} margin="0" line="24px">
                  Produkty:
                </Text>
                <Text size={26} weight={700} margin="0" line="24px">
                  {filteredProducts.length}
                </Text>
              </DesktopBarProductsInfoWrapper>
              <DesktopBarSortInfoWrapper
                onClick={() =>
                  setDesktopSortDropdownOpened(!desktopSortDropdownOpened)
                }
              >
                <Text
                  size={12}
                  margin="0"
                  dangerouslySetInnerHTML={{
                    __html: `Sortowanie: <b>${sortMethod}</b>`,
                  }}
                />
                <Icon src={sortingIcon} alt="sort icon" />
              </DesktopBarSortInfoWrapper>
              <DesktopBarSortDropdownWrapper opened={desktopSortDropdownOpened}>
                {sortMethods.map((method) => (
                  <DropdownItem
                    key={method}
                    onClick={() => {
                      setSortMethod(method)
                      setDesktopSortDropdownOpened(false)
                    }}
                  >
                    <DropdownItemCheckbox active={sortMethod === method} />
                    <Text size={12} margin="0">
                      {method}
                    </Text>
                  </DropdownItem>
                ))}
              </DesktopBarSortDropdownWrapper>
            </DesktopBarWrapper>
            <ProductsWrapper>
              {pageTiles.length > 0 ? (
                pageTiles.map((product, index) => (
                  <ProductTileWrapper
                    key={`${product.name}-${index}`}
                    as={Link}
                    to={product.link}
                  >
                    <ProductImageWrapper>
                      <LazyImage
                        src={product.image.src}
                        alt={product.image.alt}
                        objectFit="contain"
                        fill
                      />
                    </ProductImageWrapper>
                    <ProductInfoWrapper>
                      <ProductNameText
                        dangerouslySetInnerHTML={{ __html: product.name }}
                      />
                      {product.priceText && (
                        <ProductPriceText
                          dangerouslySetInnerHTML={{
                            __html: product.priceText,
                          }}
                        />
                      )}
                    </ProductInfoWrapper>
                  </ProductTileWrapper>
                ))
              ) : (
                <Text size={21} weight={700} margin="100px">
                  Brak produktów
                </Text>
              )}
            </ProductsWrapper>
          </MainViewWrapper>
        </MainWrapper>
        {filteredProducts.length > 0 && (
          <Pagination
            changePage={changePage}
            currentPage={currentPage}
            maxPage={maxPage}
          />
        )}
      </Container>
      <MobileMenuWrapper active={mobileMenu.opened}>
        <SelectButtonsWrapper>
          <SelectButton
            active={mobileMenu.mode === 'category'}
            onClick={() => {
              if (mobileMenu.mode !== 'category') {
                setMobileMenu({
                  opened: true,
                  mode: 'category',
                })
              } else {
                setMobileMenu({
                  opened: false,
                  mode: 'category',
                })
              }
            }}
          >
            Kategorie
            <SelectButtonIcon
              src={mobileMenu.mode === 'category' ? closeIcon : menuIcon}
              alt="menu icon"
            />
          </SelectButton>
          <SelectButton
            active={mobileMenu.mode === 'sorting'}
            onClick={() => {
              if (mobileMenu.mode !== 'sorting') {
                setMobileMenu({
                  opened: true,
                  mode: 'sorting',
                })
              } else {
                setMobileMenu({
                  opened: false,
                  mode: 'sorting',
                })
              }
            }}
          >
            Sortowanie
            <SelectButtonIcon
              src={mobileMenu.mode === 'sorting' ? closeIcon : sortingIcon}
              alt="sorting icon"
            />
          </SelectButton>
        </SelectButtonsWrapper>
        {mobileMenu.mode === 'sorting' && (
          <MobileSortingMenuWrapper>
            {sortMethods.map((method) => (
              <DropdownItem
                key={method}
                onClick={() => {
                  setSortMethod(method)
                  setDesktopSortDropdownOpened(false)
                }}
              >
                <DropdownItemCheckbox active={sortMethod === method} />
                <Text size={12} margin="0">
                  {method}
                </Text>
              </DropdownItem>
            ))}
          </MobileSortingMenuWrapper>
        )}
        {mobileMenu.mode === 'category' && (
          <MobileCategoryMenuWrapper>
            {categories.map((category) => (
              <DropdownItem
                key={category}
                onClick={() => {
                  setActiveCategory(
                    activeCategory === category ? null : category
                  )
                  setInputValue('')
                  changePage(0)
                }}
              >
                <DropdownItemCheckbox active={activeCategory === category} />
                <Text size={12} margin="0">
                  {category}
                </Text>
              </DropdownItem>
            ))}
          </MobileCategoryMenuWrapper>
        )}
      </MobileMenuWrapper>
    </Wrapper>
  )
}

export default AccessoriesBrowser
