import React, { useState } from 'react'
import styled from 'styled-components'

import arrowLeftPaginationIcon from 'assets/icons/arrow-left-pagination.svg'
import arrowRightPaginationIcon from 'assets/icons/arrow-right-pagination.svg'
import arrowDownPaginationIcon from 'assets/icons/arrow-down-pagination.svg'

const PaginationWrapper = styled.div`
  padding: 36px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`

const PaginationButton = styled.button`
  background-color: ${({ theme }) => theme.colors.black};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 39px;
  height: 39px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  transition: 0.3s;

  &[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
    pointer-events: none;
  }
`

const PaginationInfoWrapper = styled.div`
  position: relative;
`

// const PaginationDropdownButton = styled.button`
//   background: none;
//   border: none;
//   padding: 13px 11px;
//   cursor: pointer;
// `

// const PaginationDropdown = styled.div<{ isOpen: boolean }>`
//   position: absolute;
//   top: 100%;
//   visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
//   opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
//   transition: 0.3s;
//   display: flex;
//   flex-direction: column;
// `

// const PaginationPageButton = styled.button<{ active: boolean }>`
//   width: 100px;
//   height: 24px;
//   border: 1px solid #b9b9b9;
//   background: ${({ active }) => (active ? '#b9b9b9' : 'white')};
//   transition: 0.3s;
//   cursor: pointer;
// `

type Props = {
  changePage: (page: number) => void
  currentPage: number
  maxPage: number
}

const Pagination: React.FC<Props> = ({ changePage, currentPage, maxPage }) => {
  // const [isPaginationDropdownOpen, setIsPaginationDropdownOpen] =
  //   useState(false)

  return (
    <PaginationWrapper>
      <PaginationButton
        onClick={() => changePage(currentPage - 1)}
        disabled={currentPage <= 0}
      >
        <img src={arrowLeftPaginationIcon} alt="arrow left" />
      </PaginationButton>
      <PaginationInfoWrapper>
        {currentPage + 1} / {maxPage + 1}
        {/* <PaginationDropdownButton
          onClick={() => setIsPaginationDropdownOpen(!isPaginationDropdownOpen)}
        >
          <img src={arrowDownPaginationIcon} alt="arrow down" />
        </PaginationDropdownButton>
        <PaginationDropdown isOpen={isPaginationDropdownOpen}>
          {Array.from({ length: maxPage + 1 }, (_, i) => (
            <PaginationPageButton
              key={i}
              onClick={() => {
                changePage(i)
                setIsPaginationDropdownOpen(false)
              }}
              active={currentPage === i}
            >
              {i + 1}
            </PaginationPageButton>
          ))}
        </PaginationDropdown> */}
      </PaginationInfoWrapper>
      <PaginationButton
        onClick={() => changePage(currentPage + 1)}
        disabled={currentPage >= maxPage}
      >
        <img src={arrowRightPaginationIcon} alt="arrow right" />
      </PaginationButton>
    </PaginationWrapper>
  )
}

export default Pagination
